import React, { useState, useEffect } from "react";
import FormGroup from '@mui/material/FormGroup';
import Typography from '@material-ui/core/Typography';
import AccordionNextButton from "../AccordionNextButton";
import TagContainer from "./TagContainer";
import InactiveTagContainer from "./InactiveTagContainer";
import HttpService from "../../../HttpService";
import "animate.css/animate.css";
import './styles/Tags.css';

const Tags = (props) => {
  const [tagList, setTagList] = useState([]);

  useEffect(() => {
    if(props.info[2].quoteDescriptions_data?.weight !== undefined && props.info[2].quoteDescriptions_data?.pieces !== undefined)
      fetchQuoteTags();
  }, [props.info[2].quoteDescriptions_data?.weight, props.info[2].quoteDescriptions_data?.pieces]);

  const fetchQuoteTags = async () => {
    HttpService.getQuoteTags(props.quoteId).then(async function(response){  
      console.log("getQuoteTags" + JSON.stringify(response.data));
      var tempTags = [];
     
      await response.data.forEach((element) => {
        console.log("fetchQuoteTags-element: ", element);   
        try {   
          const restrictions = element.variableDefinition !== null ? JSON.parse(element.variableDefinition) : null;          
          console.log("restrictions: ", JSON.stringify(restrictions));
          element.checked = element.alwaysOn || element.defaultOn;
          if(restrictions !== undefined && restrictions !== null) {    
            let resList = [];
            restrictions.forEach((restriction) => {      
              if (restriction.type === "dropdown" ) {  
                restriction.choices = restriction.values.map((item) => {
                  return {label: item};
                });
                restriction.value = restriction.default;          
              }
              else {
                if(restriction.default && restriction.type === "checkbox")
                  restriction.value = parseInt(restriction.default);
                else if(restriction.default)
                  restriction.value = restriction.default;  
                else
                  restriction.value = restriction.type === 'integer' ? 0 : 
                                    restriction.type === 'decimal' ? 0.0 : 
                                    restriction.type === 'datetime' ? new Date() :
                                    restriction.type === 'date' ? new Date() : 
                                    restriction.type === 'time' ? new Date() : 
                                    restriction.type === 'checkbox' ? 1 : '';      
              }
              restriction.prompt = (!restriction.prompt) ? element.clientLabel : restriction.prompt;      
              // Only add alerts for alwaysOn tags
              if (element.alwaysOn) {    
                if(restriction.type !== "dropdown" && restriction.type !== "datetime" && 
                   restriction.type !== "date" && restriction.type !== "time" && 
                   restriction.type === 'checkbox'){          
                  handleAlert.addAlert(restriction.prompt);                  
                }
              }  
              if(restriction.newline === undefined || restriction.newline === '0') {    
                restriction.newline = false;
                resList.push(restriction);
              }  
              else {
                resList.push({param: "newLine", type: "newline", newline: true});
                restriction.newline = false;
                resList.push(restriction)
              }      
            })
            element.restrictions = resList;      
          }
          else {
            element.restrictions = [{ type: 'none' }];
          }
          tempTags.push(element);
        }
        catch(e) {
          console.log(element.variableDefinition + "-exception-" + e);
        }
      });
      setTagList(tempTags);    
      console.log("fetchQuoteTags-tagList: ", JSON.stringify(tempTags));
    });  
  };
 
  const handleChange = (id) => (event) => {
    console.log("Tag handleChange-id: "+ id);
    props.onChange({"target": event.name, "value": event.value});  
    const updatedTagList = tagList.map(element => {
      if(element.quoteTagID === id) {
        const updatedRestrictions = element.restrictions.map(restriction => {
          if(restriction.param === event.name) {
            return {...restriction, value: event.value};
          }
          return restriction;
        });
        return {...element, restrictions: updatedRestrictions};
      }
      return element;
    });
    setTagList(updatedTagList);   
    props.setCompletedStep(3);   
  };

  const handlerCheckedChange = (id) => {
    console.log(`Tag ${id} checked/unchecked`);

    const updatedTagList = tagList.map(tag => {
      if (tag.quoteTagID === id) {
        const isChecked = !tag.checked;
        tag.checked = isChecked;
        
        if (isChecked) {
          const element = document.querySelector(`#tag-${id}`);
          if (element) {
            element.classList.add("flash");
            setTimeout(() => element.classList.remove("flash"), 500);
          }
        }
      }
      return tag;
    });
    
    setTagList(updatedTagList);
  };

  const handleAlert = {
    addAlert: (event) => {
      props.onAlert({"target": event, "value": true});      
    },
    removeAlert: (event) => {    
      props.onAlert({"target": event, "value": false});       
    }
  };

  const checkIsFilledRequiredFields = () => {
    var flag = true;
    tagList.forEach((element) => {     
      if(element.checked && element.alwaysOn) { // Only check required fields if tag is alwaysOn
        element.restrictions.forEach((restriction) => {            
          if(restriction.required && (
            (restriction.type === 'varchar' && restriction.value === '') || 
            (restriction.type === 'memo' && restriction.value === '')
          )) {             
            flag = false;
          }
        });
      }
    });
    console.log("handelNextPanel-checkIsFilledRequiredFields:" + flag);
    return flag;  
  };
  
  const handelNextPanel = async () => { 
    if(checkIsFilledRequiredFields()) {      
      tagList.forEach(async (element) => {     
        let postTag = {}; 
        if(element.checked) {
          postTag.checked = "1";
          element.restrictions.forEach((restriction) => {
            if(!restriction.newline)
              postTag[restriction.param] = restriction.value;
          });
          console.log("postQuoteTags tag", JSON.stringify(postTag));
          let res = await HttpService.postQuoteTags(element.quoteTagID, JSON.stringify(postTag));
          console.log("postQuoteTags return", res.data);
        }
        else {
          postTag.checked = "0";  
          console.log("postQuoteTags tag", JSON.stringify(postTag));  
          let res = await HttpService.postQuoteTags(element.quoteTagID, JSON.stringify(postTag));
          console.log("postQuoteTags return", res.data); 
        }  
      });  
    } 
    props.nextPanel();       
  };

  return (
    <div className="tags-container">
      <FormGroup>
        {/* Active Tags Section */}
        <div className="active-tags-container">
          {tagList.filter(tag => tag.checked).map((element) => (
            <TagContainer
              key={element.quoteTagID}
              id={`tag-${element.quoteTagID}`}
              label={element.clientLabel}
              variant="outlined"
              required={element.alwaysOn} // Only pass required if alwaysOn
              onAlert={handleAlert}
              onChange={handleChange(element.quoteTagID)}
              onCheckedChange={() => handlerCheckedChange(element.quoteTagID)}
              info={element}
              restrictions={element.restrictions}
              element={element}
            />
          ))}
        </div>

        {/* Inactive Tags Section */}
        <div className="inactive-tags-section">
          <Typography variant="h6" className="section-title">
            Available Tags
          </Typography>
          <div className="inactive-tags-container">
            {tagList.filter(tag => !tag.checked).map((element) => (
              <InactiveTagContainer
                key={element.quoteTagID}
                id={`tag-${element.quoteTagID}`}
                label={element.clientLabel}
                variant="outlined"
                required={element.alwaysOn} // Only pass required if alwaysOn
                onAlert={handleAlert}
                onChange={handleChange(element.quoteTagID)}
                onCheckedChange={() => handlerCheckedChange(element.quoteTagID)}
                info={element}
                restrictions={element.restrictions}
                element={element}
              />
            ))}
          </div>
        </div>

        <div className="div-padding" />
        <AccordionNextButton 
          onClick={handelNextPanel}
          disabled={false}
        />
      </FormGroup>
    </div>
  );
};

export default Tags;