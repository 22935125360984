import React, { useState, useEffect, useRef } from 'react';
import { Button, ButtonGroup, ListItem, List, Tooltip, makeStyles } from '@material-ui/core';
import PanelItem from './PanelItem';
import './AddressPanel.css';
import httpRequest from '../../../../HttpService';
import AddressForm from '../addressForm';
import CustomInput from '../../inputFields/CustomInput';
import { useUserContext } from "../../../../lib/user_context";

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column', 
      height: '100%'
    },
    searchContainer: {
      marginBottom: theme.spacing(1)
    },
    listContainer: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
      marginTop: theme.spacing(1)
    },
    listItem: {
      padding: theme.spacing(1, 0)
    }
   }));

const AddressPanel = (props) => {
    const classes = useStyles();
    const { sessionAccountAddress } = useUserContext();
    const [googleAPILoaded, setGoogleAPILoaded] = useState(false);
    const [addrList, setAddrList] = useState([]);
    const [addrState, setAddrState] = useState(false);
    const [selectedAddrIndex, setSelectedAddrIndex] = useState(props.initialSelected);
    const [query, setQuery] = useState(""); 
    const [showForm, setShowForm] = useState(false);
    const [newAddress, setNewAddress] = useState('');
    const [removedAddressIndex, setRemoveAddressIndex] = useState('');
    const [noResultsFound, setNoResultsFound] = useState(false);
    const placeInputRef = useRef(null);
    const [newAddressValues, setNewAddressValues] = useState(null);
    const [googleSearchHandler, setGoogleSearchHandler] = useState(null);
    const [googleAutocomplete, setGoogleAutocomplete] = useState(null);

    useEffect(() => {
        const loadGoogleMapScript = (callback) => {
            if (typeof window.google === 'object' && typeof window.google.maps === 'object') {               
                callback();
            } else {
                const googleMapScript = document.createElement("script");
                googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`;
                window.document.body.appendChild(googleMapScript);               
                googleMapScript.addEventListener("load", callback);
            }
        }
        loadGoogleMapScript(() => {          
            setGoogleAPILoaded(true);
        });
    }, []);

    useEffect(() => {
        setSelectedAddrIndex(props.initialSelected);
    }, [props.initialSelected]);

    const handleListItemClick = (index) => {
        setSelectedAddrIndex(index);       
        props.onChange(addrList[index], props.type);    
    };

    const handleSearch = (event) => {
        setQuery(event.target.value);
        setSelectedAddrIndex("");
        setNoResultsFound(false);
    }

    const handleClick = (event) => {        
        setShowForm(!showForm); 
        setNoResultsFound(false);
    };

    function handleNewAddress(newAddress) {
        setNewAddress(newAddress);
        console.log('New Address added', newAddress);
        setAddrState(addrList.unshift(newAddress));
        setSelectedAddrIndex(0);
        props.onChange(newAddress, props.type);
    }

    function handleRemoveAddress(removedAddressIndex) {
        setRemoveAddressIndex(removedAddressIndex);
        console.log('Removed Address - index', removedAddressIndex);
        setAddrState(addrList.splice(removedAddressIndex, 1));
        setSelectedAddrIndex('');
    }

    useEffect(() => {
        // Reset noResultsFound when query changes
        setNoResultsFound(false);
        
        async function getAddr() {
            if(!props.accountNumber) return;
            const addressList = [];
            httpRequest.getTopAddressByType(props.accountNumber, {$: query}, props.type).then(async function(body){
                var userAddress = sessionAccountAddress;
                var defaultAddress = null;
                if(userAddress !== ""){
                    defaultAddress = JSON.parse(userAddress);                        
                }           
                await body.data.forEach((value) => {
                    if(defaultAddress==null || value.addressID !== defaultAddress.addressID )
                    if(defaultAddress == null || value.addressID != defaultAddress.addressID )
                        addressList.push(value);
                });
                if(defaultAddress && query.length == 0)
                    addressList.unshift(defaultAddress);

                if(addressList.length > 0) {                    
                    let listWithID =  addressList.map((value,index) => {
                        value.id = index;             
                        return value;
                    });
                    setAddrList(listWithID);

                    if(props.type !== "shipper"){
                        const deliveryLocationStr = localStorage.getItem("deliveryLocation"); 
                        if(deliveryLocationStr !== undefined && deliveryLocationStr !== null && deliveryLocationStr !== ""){
                            console.log("retrieved deliveryLocationStr is " + deliveryLocationStr);                    
                            var deliveryLocation = JSON.parse(deliveryLocationStr);
                            console.log("retrieved deliveryLocation  addressID" + deliveryLocation.addressID); 
                            const index = listWithID.findIndex((obj) => obj.addressID === deliveryLocation.addressID);   
                            if(index >= 0){
                                setSelectedAddrIndex(index);
                                props.onChange(listWithID[index], props.type, true); 
                            }  
                            else{
                                setSelectedAddrIndex("");
                            }
                        }
                        else
                           setSelectedAddrIndex("");
                    }
                     
                    setAddrState(true);
                    if(props.type === "shipper" && query === "") {
                        const pickupLocationStr = localStorage.getItem("pickupLocation");
                        if(pickupLocationStr !== undefined && pickupLocationStr !== null && pickupLocationStr !== ""){
                            console.log("retrieved pickupLocationStr is " + pickupLocationStr);                    
                            var pickupLocation = JSON.parse(pickupLocationStr);
                            console.log("retrieved pickupLocation  addressID" + pickupLocation.addressID); 
                            const index = listWithID.findIndex((obj) => obj.addressID === pickupLocation.addressID);   
                            if(index >= 0){
                                setSelectedAddrIndex(index);
                                props.onChange(listWithID[index], props.type, true); 
                            }  
                            else{
                                setSelectedAddrIndex(0);
                                props.onChange(listWithID[0], props.type, true);
                            }
                        }
                        else{
                            setSelectedAddrIndex(0);
                            props.onChange(listWithID[0], props.type, true);
                        }
                    }
                } else {
                    if (query.length > 4) {
                        expandSearchAddr();
                    }
                }
            });
        }

        async function expandSearchAddr() {
            if(!props.accountNumber) return;
            const expandedSearchAddressList = [];
            httpRequest.getExpandedAddress(props.accountNumber,{$: query}, 0).then(async function(body){
                await body.data.forEach((value) => {
                    expandedSearchAddressList.push(value);
                });

                if(expandedSearchAddressList.length > 0) {
                    await setAddrList(expandedSearchAddressList);
                    setAddrState(true);
                    if(props.type === "shipper" && query === "") {
                        setSelectedAddrIndex(0);
                        props.onChange(expandedSearchAddressList[0], props.type, true);
                    }
                } else {
                    setAddrList([]);
                    setAddrState(false);
                    setNoResultsFound(true);
                }
            });
        }

        if(!showForm && sessionAccountAddress !== null) {
            getAddr();
        }
    },[sessionAccountAddress, props.accountNumber, props.quoteId, query]);

    useEffect(() => {
        if(googleAPILoaded && placeInputRef.current) {
            try {
                // Default coordinates for Calgary if we can't get from session
                let geolocation = {
                    lat: 51.04522,
                    lng: -114.06302
                };     
    
                // Only try to get from session if it exists
                if (sessionAccountAddress) {
                    const defaultAddress = JSON.parse(sessionAccountAddress);
                    if (defaultAddress?.lat && defaultAddress?.long) {
                        geolocation = {
                            lat: defaultAddress.lat,
                            lng: defaultAddress.long
                        };
                    }
                }
        
                var circle = new window.google.maps.Circle({center: geolocation, radius: 5000});
                let options = {
                    bounds: circle.getBounds()
                };
        
                let autocomplete = new window.google.maps.places.Autocomplete(placeInputRef.current, options);
                new window.google.maps.event.addListener(autocomplete, "place_changed", function() {
                    let place = autocomplete.getPlace();
                    let placeFormatted = convertPlaceToFriendlyObject(place);
                    populateMatchedAddress(placeFormatted);
                    setShowForm(true);
                });
            } catch (error) {
                console.error("Error setting up Google Places:", error);
            }
        }
    }, [googleAPILoaded]);

    function convertPlaceToFriendlyObject(place) {
        let result = {};
        if(place) {
            let components = place.address_components;

            for( let i = 0; i < components.length; i++ ) {
                if (i === 0) {
                    result.searchBy = components[i].types[0];
                }
                result[components[i].types[0]] = components[i].long_name;
            }
         }
        result.formattedAddress = place.formatted_address;
        result.lat = place.geometry.location.lat();
        result.lng = place.geometry.location.lng();
        result.name = place.name;
        result.place_id = place.place_id;

        return result;
   }

    function populateMatchedAddress (myAddress) {
        let tempAddressValues = {};
        if(myAddress.route !== undefined){
            if(myAddress.route.endsWith("Southwest")) {
                tempAddressValues.quad = "SW";
                tempAddressValues.address = myAddress.street_number + ' ' + myAddress.route.substring(0, myAddress.route.indexOf("Southwest"));
            }
            else if(myAddress.route.endsWith("Southeast")) {
                tempAddressValues.quad = "SE";
                tempAddressValues.address = myAddress.street_number + ' ' + myAddress.route.substring(0, myAddress.route.indexOf("Southeast"));
            }
            else if(myAddress.route.endsWith("Northwest")) {
                tempAddressValues.quad = "NW";
                tempAddressValues.address = myAddress.street_number + ' ' + myAddress.route.substring(0, myAddress.route.indexOf("Northwest"));
            }
            else if(myAddress.route.endsWith("Northeast")) {
                tempAddressValues.quad = "NE";
                tempAddressValues.address = myAddress.street_number + ' ' + myAddress.route.substring(0, myAddress.route.indexOf("Northeast"));
            }
            else {
                tempAddressValues.quad = "";
                tempAddressValues.address = myAddress.street_number + ' ' + myAddress.route;
            }
        }
        else {
            let arr = myAddress.formattedAddress.split(',');
            if(arr[0].includes('Southwest')) {
                tempAddressValues.quad = "SW";
                tempAddressValues.address = arr[0].substring(0,arr[0].indexOf('Southwest'));
            }
            else if(arr[0].includes('Southeast')) {
                tempAddressValues.quad = "SE";
                tempAddressValues.address = arr[0].substring(0,arr[0].indexOf('Southeast'));
            }
            else if(arr[0].includes('Northwest')) {
                tempAddressValues.quad = "NW";
                tempAddressValues.address = arr[0].substring(0,arr[0].indexOf('Northwest'));
            }
            else if(arr[0].includes('Northeast')) {
                tempAddressValues.quad = "NE";
                tempAddressValues.address = arr[0].substring(0,arr[0].indexOf('Northeast'));
            }
            else {
                tempAddressValues.quad = '';
                tempAddressValues.address = arr[0];
            }
        }

        tempAddressValues.city = myAddress.locality;
        tempAddressValues.country = myAddress.country;
        tempAddressValues.postal = myAddress.postal_code;
        tempAddressValues.lat = myAddress.lat;
        tempAddressValues.long = myAddress.lng;
        tempAddressValues.prov =  myAddress.administrative_area_level_1;
        tempAddressValues.companyName = myAddress.name;

        setNewAddressValues(tempAddressValues);
    }

    const predictionsList = () => {
        return (
            <>
            </>
        );
    }

    const listItems = data => {
        return data.map((item, index) => (
            <ListItem 
                button 
                className={`panel-list-item ${classes.listItem}`}
                divider={true}
                disabled={props.pickUpLocation ? props.pickUpLocation.addressID === item.addressID : false}
                selected={selectedAddrIndex === index}
                autoFocus={selectedAddrIndex === index}
                onClick={() => {
                    handleListItemClick(index);
                    setNewAddressValues(null);
                    placeInputRef.current.value = '';                                      
                }}
                key={`address-list-item-${index}`}                                 
            > 
                <PanelItem                    
                    selected={selectedAddrIndex === index} 
                    data={item} 
                    index={index}
                    type={props.type}
                    key={`address-panel-item-${index}`}
                    onChange={props.onChange}
                    removedAddressIndex={handleRemoveAddress} 
                    onChangeContact={props.onChangeContact}   
                    accountNumber={props.accountNumber}                                     
                /> 
            </ListItem>
        ));
    };

    return (
        <div className={classes.root}>
            <div className={classes.searchContainer}>
                <Tooltip title="Select the delivery address or type" placement="top" arrow>
                    <div>
                        <CustomInput
                            type="name"
                            name={props.type === "consignee" ? "DeliverySearch:" : "PickupSearch"}
                            icon="AddOutlinedIcon"
                            inputType="text"
                            label={props.type === "consignee" ? "Delivery Location:" : "Pickup Location"}
                            onIconClick={handleClick}
                            labelSize={5}
                            onChange={handleSearch}
                            inputRef={placeInputRef}
                            value={query}
                        />
                        {predictionsList()}
                    </div>
                </Tooltip>
            </div>
            
            {showForm && (
                <>
                    <div 
                        style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            background: 'rgba(0,0,0,0.5)',
                            zIndex: 1000
                        }} 
                        onClick={() => {
                            handleClick();
                            setNewAddressValues(null);
                            placeInputRef.current.value = '';
                            setQuery("");
                        }}
                    />
                    <AddressForm 
                        accountNumber={props.accountNumber}
                        title="New Address" 
                        saveButtonLabel="Save" 
                        cancelButtonLabel="Cancel" 
                        variant="add" 
                        cancelButtonOnClick={() => {
                            handleClick();
                            setNewAddressValues(null);
                            placeInputRef.current.value = '';
                            setQuery("");
                        }}
                        afterSaveAddress={() => {
                            handleClick();
                            setNewAddressValues(null);
                        }}  
                        newAddress={handleNewAddress}
                        data={newAddressValues}
                    />
                </>
            )}
            
            {addrState && noResultsFound && (
                <div 
                    style={{ 
                        padding: '15px', 
                        textAlign: 'center', 
                        color: '#888' 
                    }}
                >
                    No addresses found. Try a different search or add a new address.
                </div>
            )}

            {addrState && !noResultsFound && (
                <div className={`panel-list ${classes.listContainer}`} id="addr-form">
                    <List className="panel-list-container" disablePadding={true} key="addr-list" alignItems="flex-start">
                        {listItems(addrList)}
                    </List>
                </div>
            )}
        </div>
    );
}

export default AddressPanel;