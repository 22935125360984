import React, { useState, useEffect } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { pad2 } from "./helperFunctions/index.js";
import "../NewOrder.css";

const CountdownTimer = ({ countTimerStatus, currentSection }) => {
  const [duration, setDuration] = useState(-1);
  const [durationText, setDurationText] = useState("");
  const [intervalId, setIntervalId] = useState(-1);

  useEffect(() => {
    // Clear timer when component unmounts or when section changes
    return () => {
      if (intervalId !== -1) {
        clearInterval(intervalId);
        setIntervalId(-1);
      }
    };
  }, []);

  useEffect(() => {
    // Always clean up existing timer when section changes
    if (intervalId !== -1) {
      clearInterval(intervalId);
      setIntervalId(-1);
      setDuration(-1);
      setDurationText("");
    }

    // Only start new timer if we're in section 4
    if (currentSection === 4 && countTimerStatus === 1) {
      startTimer();
    }
  }, [currentSection, countTimerStatus]);

  useEffect(() => {
    if (duration === 90) {
      setDuration(-1);
      clearInterval(intervalId);
      setIntervalId(-1);
      setDurationText("");
    } else if (duration !== -1) {
      setDurationText(
        pad2((90 - duration) / 60) + ":" + pad2((90 - duration) % 60)
      );
    }
  }, [duration]);

  const cleanTimer = () => {
    if (intervalId !== -1) {
      clearInterval(intervalId);
      setIntervalId(-1);
    }
    setDuration(-1);
    setDurationText("");
  };

  const startTimer = () => {
    cleanTimer(); // Clean up any existing timer first
    setDuration(0);
    const newIntervalId = setInterval(() => {
      setDuration(duration => duration + 1);
    }, 1000);
    setIntervalId(newIntervalId);
  };

  // Only render if we're in section 4 and have an active timer
  if (currentSection !== 4 || duration === -1) {
    return null;
  }

  return (
    <div id="indicator_duration">
      <CircularProgressbar
        value={duration}
        text={durationText}
        minValue="0"
        maxValue="92"
        strokeWidth={6}
        styles={buildStyles({
          rotation: 0.25,
          textSize: "28px",
          pathTransitionDuration: 0.5,
          textColor: "red",
        })}
      />
    </div>
  );
};

export default CountdownTimer;