import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// Custom CSS for Tidio positioning
import './tidio-positioning.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { UserProvider } from "./lib/user_context";

import ReHelmet from "./Helmet";

// Create a TidioLoader component to handle script loading and CSS injection
const TidioLoader = () => {
  useEffect(() => {
    // Check if Tidio script is already loaded
    if (!document.querySelector('script[src="//code.tidio.co/c9qnm0vk1h183dspvaeg2nir0zq8ixfh.js"]')) {
      const script = document.createElement('script');
      script.src = "//code.tidio.co/c9qnm0vk1h183dspvaeg2nir0zq8ixfh.js";
      script.async = true;
      document.body.appendChild(script);
    }
  }, []);

  return null;
};

ReactDOM.render(
    <>
        <ReHelmet title="Lattice Webx" />
        <UserProvider>
          <App />
          <TidioLoader />
        </UserProvider>
    </>,
    document.getElementById('root')
);

reportWebVitals();