import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@mui/material/Grid';
import Typography from '@material-ui/core/Typography';
import PackageOptions from './PackageOptions';
import styles from './styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { ThemeProvider, withStyles } from '@material-ui/styles';
import { createTheme } from '@material-ui/core';
import Skeleton from '@mui/material/Skeleton';
import Alert from '@mui/material/Alert';

const StyledToggleButtonGroup = withStyles((theme) => ({
    grouped: {
        margin: 0,
        border: 0,
        direction: "horizontal",
        flexWrap: 'wrap',
    }
}))(ToggleButtonGroup);

const StyledToggleButton = withStyles({
    root: {
        padding: 0,
        textTransform: "none",
        display: "block",
        width: "180px",
        height: "180px",
        "& img": {
            border: '2px solid #e5e5e5',
            borderRadius: '7px',
        },
        "&:hover": {
            "& img": {
                color: "rgb(26, 88, 159)",
                border: "2px solid #337AB7"
            }
        },
    },
    selected: {
        "& img": {
            color: "rgb(26, 88, 159)",
            border: "4px solid #337AB7",
            backgroundColor: "#337AB720"
        }
    }
})(ToggleButton);

const theme = createTheme({
    palette: {
        action: {
            active: "#EEEEEE"
        },
        text: {
            primary: "#EEEEEE"
        }
    }
});

function AllPackageOptions(props) {
    const [packageSize, setSize] = React.useState("");
    const [packageOptionList, setPackageOptionList] = useState(props.packageOptionList);
    const [isLoading, setIsLoading] = useState(props.isLoading);

    useEffect(() => {
        setPackageOptionList(props.packageOptionList);
        setIsLoading(props.isLoading);
    }, [props.packageOptionList, props.isLoading]);

    const handleSize = (event, newSize) => {
        setSize(newSize);
        props.onChange({
            target: {
                name: "packageSize",
                value: newSize,
            }
        });

        const selectedOption = packageOptionList.find(e => e.iconTitleText === newSize) || {};

        if (!props.hasManualEdits) {
            props.onChange({
                target: {
                    name: "pieces",
                    value: selectedOption.fillPieces || 0
                }
            });

            props.onChange({
                target: {
                    name: "weight",
                    value: selectedOption.fillWeight || 0
                }
            });
        }

        props.onChange({
            target: {
                name: "packageDesc",
                value: selectedOption.iconTitleText
            }
        });

        props.onChange({
            target: {
                name: "packageImage",
                value: selectedOption.icon_url
            }
        });
    };

    const LoadingSkeletons = () => (
        <Grid container spacing={2} style={{ padding: '0 15px' }}>
            {[1, 2, 3, 4].map((n) => (
                <Grid item key={n} xs={12} sm={6} md={4} lg={3}>
                    <Box sx={{ width: 180, height: 220, mb: 4 }}>
                        <Skeleton 
                            variant="rounded" 
                            width={180} 
                            height={180} 
                            style={{ borderRadius: '7px' }}
                        />
                        <Skeleton 
                            variant="text" 
                            width={180} 
                            height={40}
                            style={{ marginTop: '8px' }}
                        />
                    </Box>
                </Grid>
            ))}
        </Grid>
    );

    const renderContent = () => {
        if (isLoading) {
            return <LoadingSkeletons />;
        }

        if (!packageOptionList || packageOptionList.length === 0) {
            return (
                <Alert 
                    severity="error" 
                    style={{ marginBottom: '20px', width: '100%' }}
                >
                    No package types available. Please try again later.
                </Alert>
            );
        }

        return (
            <StyledToggleButtonGroup
                size="small"
                value={props.packageSize}
                exclusive
                onChange={handleSize}
                style={styles.root}
            >
                {packageOptionList.map((option) => (
                    <StyledToggleButton
                        key={option.iconTitleText}
                        value={option.iconTitleText}
                        disableRipple
                        style={{ marginBottom: '40px' }}
                    >
                        <PackageOptions
                            packageType={option.iconTitleText}
                            image={option.icon_url}
                            alt={option.iconTitleText}
                        />
                    </StyledToggleButton>
                ))}
            </StyledToggleButtonGroup>
        );
    };

    return (
        <div>
            <div style={{ paddingLeft: '45px' }}>
                <ThemeProvider theme={theme}>
                    {renderContent()}
                </ThemeProvider>
            </div>
            <div style={{ paddingBottom: '20px' }} />
        </div>
    );
}

export default AllPackageOptions;