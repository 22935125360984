import React, { useState } from 'react';
import Button from "@material-ui/core/Button";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

const AccordionNextButton = ({ 
  onClick, 
  disabled = false,
  errorMessage = '',
}) => {
  const [isProcessing, setIsProcessing] = useState(false);

  const handleClick = async () => {
    if (isProcessing) return;
    
    setIsProcessing(true);
    try {
      await onClick();
    } catch (error) {
      console.error('Error in button click handler:', error);
    } finally {
      // Reset after 500ms to prevent double clicks
      setTimeout(() => {
        setIsProcessing(false);
      }, 500);
    }
  };

  return (
    <div style={{ marginTop: '16px' }}>
      <Button
        variant="contained"
        color="primary"
        endIcon={<KeyboardArrowRightIcon />}
        onClick={handleClick}
        disabled={disabled || isProcessing}
        style={{
          backgroundColor: '#337AB7',
          width: '200px'
        }}
      >
        Next
      </Button>
      {errorMessage && errorMessage.split('\n').map((msg, i) => (
        <div 
          key={i}
          style={{ 
            marginTop: '8px', 
            color: '#f44336', 
            fontSize: '0.875rem' 
          }}
        >
          {msg}
        </div>
      ))}
    </div>
  );
};

export default AccordionNextButton;