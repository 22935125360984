import { palette } from "../../../../palette";

const styles = {
  root: {
    display: 'flex',
  },
  cardHeader: {
    display: 'flex',
    width: '100%',
    overflowWrap: 'normal',
    fontWeight: 'bold',
    wordWrap: 'break-word', // Ensure long words break properly
  },
  cardTitleIcon: {
    display: 'inline',
    width: '44px',
    marginRight: '5px',
    height: '44px',
  },
  cardTitleRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
  },
  dateTimePicker: {
    marginRight: '30px',
  },
  description: {
    overflowWrap: 'break-word',   // Ensures text wraps at word boundaries
    whiteSpace: 'normal',         // Allows text to wrap normally (not on a single line)
    wordWrap: 'break-word',       // For breaking long words
    width: '100%',                // Ensure the text takes up the full width of its container
    textOverflow: 'ellipsis',     // Handle overflow with ellipsis (truncated text when it overflows)
    overflow: 'hidden',           // Hide anything that overflows
    display: 'block',             // Make it a block-level element
    lineHeight: 1.5,              // Optional: Controls line height for readability
    maxHeight: '3.6em',           // Optional: Limits height to 2 lines of text
  },
  divider: {
    minWidth: '150px',
  },
  dividerWeekend: {
    minWidth: '150px',
    marginTop: -20,
  },
  inlineIcon: {
    height: 30,
    width: 30,
    marginLeft: 5,
    marginBottom: -4,
  },
  priceText: {
    textAlign: 'right',
    color: 'black',
    paddingTop: '5px',
    fontWeight: 'bold',
  },
  quickSelectRow: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  radio: {
    display: 'inline',
  },
  warningBannerBox: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f8d7d9',
    marginBottom: 4,
    border: '1px solid #f5c2c7',
    borderRadius: 4,
  },
};

export default styles;