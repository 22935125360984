import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import { Tooltip, IconButton, Button, Paper } from '@material-ui/core';
import AddBoxIcon from '@mui/icons-material/AddBox';
import ClearIcon from '@mui/icons-material/Clear';
import "devextreme/dist/css/dx.light.css";
import DataGrid, {
  Column,
  Selection,
  Pager,
  Paging
} from "devextreme-react/data-grid";
import NoticeEmitter from '../../../../NoticeUtil';

const styles = {
  root: {
    display: 'flex',
  },
  formControlSelect: {
    minWidth: 200,
    paddingRight: 20,
    paddingBottom: 16,
  },
  formControlTextfield: {
    width: 150,
    paddingRight: 20,
    paddingBottom: 16,
  },
  inputContainer: {
    backgroundColor: '#f5f5f5',
    padding: '20px',
    borderRadius: '4px',
    marginBottom: '20px'
  },
  bold: {
    fontWeight: 600
  }
};

function DefineIndividualPieces(props) {
  const [piecesOptionList, setPiecesOptionList] = useState([]);
  const [piecesOption, setPiecesOption] = useState({
    length:'',
    height:'',
    width:'',
    weight:'',
    positions:'',
    description:''
  });

  useEffect(() => {
    props.setDefineIndividual(piecesOptionList)
  },[piecesOptionList]);

  const handleChange = (event) => {
    setPiecesOption(prevState => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleAddOption = () => {
    if(piecesOptionList.length>0) {
      const maxObj = piecesOptionList.reduce((max, obj) => obj.id > max.id ? obj : max);
      const newOption = {...piecesOption, id: maxObj.id+1}
      setPiecesOptionList(prev => [...prev, newOption]);
    } else {
      setPiecesOptionList([{...piecesOption, id: 1}]);
    }
    setPiecesOption({
      length:'',
      height:'',
      width:'',
      weight:'',
      positions:'',
      description:''
    });
    setTimeout(() => NoticeEmitter.emit('PackagePiecesUpdate', 'add'), 500);
  };

  const renderDelete = params => (
    <IconButton  
      color="primary"             
      onClick={() => {
        setPiecesOptionList(prev => prev.filter(obj => obj.id !== params.data.id));
        setTimeout(() => NoticeEmitter.emit('PackagePiecesUpdate', 'minus'), 500);
      }}
    >   
      <ClearIcon style={{ fontSize: 12 }}/>  
    </IconButton>
  );
   
  return (
    <div style={{ width: '100%' }}>
      <Typography variant="body2" color="textSecondary" style={{marginBottom: '16px'}}>
        Add dimensions and weight for individual pieces in your shipment
      </Typography>
   
        <Paper style={styles.inputContainer}>
          <div style={{display: 'flex', alignItems: 'flex-end', gap: '16px', flexWrap: 'wrap'}}>
            <FormControl style={styles.formControlTextfield}>
              <InputLabel style={styles.bold}>Length (Inches)</InputLabel>
              <Input
                name="length"
                value={piecesOption.length}
                onChange={handleChange}
                type="number"
              />
            </FormControl>
   
            <FormControl style={styles.formControlTextfield}>
              <InputLabel style={styles.bold}>Width (Inches)</InputLabel>
              <Input
                name="width"
                value={piecesOption.width}
                onChange={handleChange}
                type="number"
              />
            </FormControl>
   
            <FormControl style={styles.formControlTextfield}>
              <InputLabel style={styles.bold}>Height (Inches)</InputLabel>
              <Input
                name="height"
                value={piecesOption.height}
                onChange={handleChange}
                type="number"
              />
            </FormControl>
   
            <FormControl style={styles.formControlTextfield}>
              <InputLabel style={styles.bold}>Weight (lbs)</InputLabel>
              <Input
                name="weight"
                value={piecesOption.weight}
                onChange={handleChange}
                type="number"
              />
            </FormControl>
   
            <FormControl style={styles.formControlTextfield}>
              <InputLabel style={styles.bold}>Skid Positions (#)</InputLabel>
              <Input
                name="positions"
                value={piecesOption.positions}
                onChange={handleChange}
                type="number"
              />
            </FormControl>
   
            <FormControl style={styles.formControlTextfield}>
              <InputLabel style={styles.bold}>Piece Description</InputLabel>
              <Input
                name="description"
                value={piecesOption.description}
                onChange={handleChange}
                type="text"
              />
            </FormControl>
   
            <Button
              variant="contained" 
              color="primary"
              startIcon={<AddBoxIcon />}
              onClick={handleAddOption}
              style={{
                height: '36px',
                backgroundColor: '#337AB7',
                width: '200px'
              }}
              disabled={!piecesOption.length && !piecesOption.width && !piecesOption.height && 
                        !piecesOption.weight && !piecesOption.positions && !piecesOption.description}
              >
              Add Piece
              </Button>
          </div>
        </Paper>
   
        <div style={{ paddingLeft: '80px', paddingTop:'10px' }}>
          {piecesOptionList.length > 0 && (
            <DataGrid
              dataSource={piecesOptionList}
              showBorders={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              keyExpr="id"
              rowAlternationEnabled={true} 
            >                    
              <Paging defaultPageSize={10} />
              <Pager
                visible={true}
                showInfo={true}
                infoText="Page #{0}. Total: {1} ({2} items)"  
                displayMode="full"
              />
              <Selection mode="single"/>
              <Column dataField="id" caption="ID" alignment="center" width={100} visible={false}/>
              <Column dataField="length" caption="Length" alignment="center" width={120}/> 
              <Column dataField="height" caption="Height" alignment="center" width={120}/> 
              <Column dataField="width" caption="Width" alignment="center" width={120}/> 
              <Column dataField="weight" caption="Weight" alignment="center" width={120}/> 
              <Column dataField="positions" caption="Positions" width={200}/> 
              <Column dataField="description" caption="Description" width={200}/>                   
              <Column dataField="id" caption="Delete" width={100} alignment="center" cellRender={renderDelete}/>
            </DataGrid>
          )}
        </div>
      </div>
   );
}

export default DefineIndividualPieces;