import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import { Tooltip, IconButton, Button } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import NoticeEmitter from '../../../../NoticeUtil';

const styles = {
  root: {
    display: 'flex',
  },
  formControlSelect: {
    minWidth: 170,
    paddingRight: 20,
    paddingBottom: 16,
  },
  formControlTextfield: {
    width: 115,
    paddingRight: 20,
    paddingBottom: 16,
  },
};

function PackageInfo(props) {
  const [pieces, setPieces] = useState(props.pieces);
  const [weight, setWeight] = useState(props.weight);
  const [packageOptionList, setPackageOptionList] = useState(props.packageOptionList);
  const [additionalDescription, setAdditionalDescription] = useState(props.additionalDescription);
  const [defineIndividual, setDefineIndividual] = useState([]);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [hasManualEdit, setHasManualEdit] = useState(false);

  useEffect(() => {    
    const callback = (data) => {
      setHasManualEdit(true);  // Consider sync from individual pieces as manual edit
    };
    
    NoticeEmitter.addListener("PackagePiecesUpdate", callback);
    return () => NoticeEmitter.removeListener("PackagePiecesUpdate", callback);
  }, []);

  useEffect(() => {    
    let p_piece = props.pieces === undefined ? 0 : props.pieces;
    setPieces(p_piece);    
    setWeight(props.weight === undefined ? 0 : props.weight);
    setPackageOptionList(props.packageOptionList);
    setAdditionalDescription(props.additionalDescription);
  },[props.weight, props.packageOptionList, props.additionalDescription]);

  useEffect(() => {    
    console.log("props.defineIndividual", props.defineIndividual);
    setDefineIndividual(props.defineIndividual);
  },[props.defineIndividual]);

  useEffect(() => {
    if (isInitialLoad && props.packageSize) {
      const selectedOption = packageOptionList.find(e => e.iconTitleText === props.packageSize) || {};
      
      // Check for both manual edits and existing values
      const hasExistingValues = pieces > 0 || weight > 0;
      
      if (!hasManualEdit && !hasExistingValues) {
        setPieces(selectedOption.fillPieces);
        setWeight(selectedOption.fillWeight);
      }
      setIsInitialLoad(false);
    }
  }, [props.packageSize, isInitialLoad, hasManualEdit]);

  useEffect(() => {
    props.onChange({
      target:{
         name: "pieces",
         value: pieces
      }
    });  
  },[pieces]);

  useEffect(() => {
    props.onChange({
      target:{
        name: "weight",
        value: weight
      }
    });
  },[weight]);

  const handleDescriptionChange = (event) => {
    props.onChange({
      target:{
        name: "additionalDescription",
        value: event.target.value
      }
    });
    setAdditionalDescription(event.target.value);
  };

  const handleWeightChange = (event) => {
    if(event.target.value === '') setWeight('');
    else {
      const newWeight = parseInt(event.target.value);
      if (newWeight < 0) setWeight(0);
      if (newWeight >= 0 && newWeight <= 99999) {
        setWeight(newWeight.toString());
      }
    }
    // Directly flag as manual edit through input
    props.onChange({
      target: {
        name: "weight",
        value: event.target.value,
        manualInput: true
      }
    });
  };
  
  const handlePiecesChange = (event) => {
    if(event.target.value === '') setPieces('');
    else {
      const newPieces = parseInt(event.target.value);
      if (newPieces < 0) setPieces(0);
      if (newPieces >= 0 && newPieces <= 999) {
        setPieces(newPieces.toString());
      }
    }
    // Directly flag as manual edit through input
    props.onChange({
      target: {
        name: "pieces",
        value: event.target.value,
        manualInput: true
      }
    });
  };

  const handlePackageChange = (event) => {
    props.onChange({
      target:{
        name: "packageDesc",
        value: event.target.value
      }
    });
  
    const selectedOption = packageOptionList.find(e => e.iconTitleText === event.target.value) || {};
  
    // If no manual edits, use the package defaults
    if (!hasManualEdit) {
      setPieces(selectedOption.fillPieces);
      setWeight(selectedOption.fillWeight);
      props.onChange({
        target:{
          name: "pieces",
          value: selectedOption.fillPieces
        }
      });
      props.onChange({
        target:{
          name: "weight",
          value: selectedOption.fillWeight
        }
      });
    }
    // If there have been manual edits, keep current values
    else {
      props.onChange({
        target:{
          name: "pieces",
          value: pieces
        }
      });
      props.onChange({
        target:{
          name: "weight",
          value: weight
        }
      });
    }
  
    props.onChange({
      target:{
        name: "packageSize",
        value: selectedOption.iconTitleText
      }
    }); 
  
    props.onChange({
      target:{
        name: "packageImage",
        value: selectedOption.icon_url
      }
    }); 
  };

  return (
    <Tooltip title="Refine even more" placement="top" arrow>
    <div>
      <div style={{ paddingLeft: '45px',paddingTop:'10px' }}>
        <FormControl style={styles.formControlTextfield}>
          <Typography style={{fontSize: 20, fontWeight: 600, marginLeft: 30}} color="primary">Total:</Typography>
        </FormControl>     
        <FormControl style={styles.formControlTextfield}>
          <InputLabel>Pieces</InputLabel>
          <Input
            name="pieces"
            value={pieces}
            onChange={handlePiecesChange}          
            type="number"
            startAdornment={
              <InputAdornment position="start">
                #
              </InputAdornment>
            }
          />
        </FormControl>
        <FormControl style={styles.formControlTextfield}>
          <InputLabel>Weight</InputLabel>
          <Input
            name="weight"
            value={weight}
            onChange={handleWeightChange}
            type="number"
            startAdornment={
              <InputAdornment position="start">
                lbs
              </InputAdornment>
            }
          />
        </FormControl>
        <FormControl>
          <InputLabel>additional descriptions</InputLabel>
          <Input
            name="additionalDescription"
            value={additionalDescription}
            onChange={handleDescriptionChange}
            type="text"
          />
        </FormControl>
      </div>
    </div>
    </Tooltip>    
  );
}

export default PackageInfo;