import React, { useState, useEffect } from "react";
import { Button, Grid,IconButton, Box } from "@material-ui/core";
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import DoneIcon from '@material-ui/icons/Done';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import InfoIcon from '@mui/icons-material/Info';
import TimerIcon from '@mui/icons-material/Timer';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import TodayIcon from '@mui/icons-material/Today';
import TimesOneMobiledataIcon from '@mui/icons-material/TimesOneMobiledata';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import RadioWithDateSelect from "./pickUpDeliveryTime/RadioWithDateSelect";
import TextInput from '../inputFields/TextInput';
import "animate.css/animate.css";
import { palette } from '../../../palette';
import httpRequest from "../../../HttpService";
import "../../NewOrder.css";

import CustomTooltip from "./pickUpDeliveryTime/CustomTooltip";

import AccordionNextButton from "../AccordionNextButton";

import Skeleton from '@mui/material/Skeleton';



const DeliveryTime = (props) => {
  const [select, setSelect] = useState(-1);
  const [open, setOpen] = React.useState(false);
  const [deliveryTimeInfo, setDeliveryTimeInfo] = useState({readyAtDateChecked:false,deliveryTimeChecked:false});
  const [quoteTimeOptionsList, setQuoteTimeOptionsList] = useState([]);
  // const [refresh, setRefresh] = useState(0);
  const [nameValue, setNameValue] = useState("");
  const [phoneValue, setPhoneValue] = useState("");
  const [pollInterval, setPollInterval] = useState(null);
  const [isLoading, setIsLoading] = useState(true);






  useEffect(() => {
    props.onAlert({"target":"timeOption", "value":true});
    props.onAlert({"target":"Initial", "value":false});
  }, []);




  const resetPollInterval =() => {
    clearInterval(pollInterval);
    const interval = setInterval(() => {
      props.updateCounterStatus(0); 
      if(props.completed.get(3) && !props.completed.get(5))
        getQuoteTimeOptions();
    }, 90000);
    setPollInterval(interval);
  }

  useEffect(() => {
    let timeoutId;
    
    // Only setup polling when in section 4 and completed section 3
    if (props.expanded === 4 && props.completed.get(3)) {
      setDeliveryTimeInfo({readyAtDateChecked:false,deliveryTimeChecked:false});
      getQuoteTimeOptions();
      
      timeoutId = setTimeout(() => {
        getQuoteTimeOptions();
      }, 500);
      
      // Setup polling interval
      if (pollInterval) {
        clearInterval(pollInterval);
      }
      const interval = setInterval(() => {
        if (props.expanded === 4) { // Only run if still in section 4
          props.updateCounterStatus(0);
          if (props.completed.get(3) && !props.completed.get(5)) {
            getQuoteTimeOptions();
          }
        }
      }, 90000);
      
      setPollInterval(interval);
      setSelect(-1);
    } else {
      // Clean up timers when not in section 4
      if (pollInterval) {
        clearInterval(pollInterval);
        setPollInterval(null);
      }
      props.updateCounterStatus(2); // Signal timer completion
    }
    
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      if (pollInterval) {
        clearInterval(pollInterval);
        setPollInterval(null);
      }
    };
  }, [props.expanded, props.completed.get(3)]);

  useEffect(() => {
    return () => {
      if (pollInterval) {
        clearInterval(pollInterval);
        setPollInterval(null);
      }
    };
  }, []);
  
  /*
  useEffect(() => {
    try{      
      //console.log("DeliveryTime useEffect props.completed.get(3) -" + props.completed.get(3));
      //console.log("DeliveryTime useEffect props.info[2].quoteDescriptions_data?.weight -" + props.info[2].quoteDescriptions_data?.weight);
      //console.log("DeliveryTime useEffect props.info[2].quoteDescriptions_data?.pieces -" + props.info[2].quoteDescriptions_data?.pieces);
      if ((props.completed.get(3) || refresh > 0) && props.info[0].quoteId!== undefined && props.info[0].quoteId!== null) {
        setDeliveryTimeInfo({});
        setSelect(-1);
        setTimeout(() =>{
          getQuoteTimeOptions();    
        }, 1000);
      

      

        httpRequest.getQuoteTimeOptions(props.info[0].quoteId).then((res) => {
          console.log("getQuoteTimeOptions", JSON.stringify(res.data));
          if (res.data.length === 0) {
            setTimeout(function() {
              setRefresh(oldRefresh => oldRefresh + 1);
            }, 500);
          } else {
            setQuoteTimeOptionsList(res.data);
                     

                     

          // let options = res.data.map((item) => {
          //    item.optionSpecialText = "warning message";
          //    item.optionSubText = "Pickup ta 10:30 and delivery at 16:30";
          //    return item;
          // });
          // setQuoteTimeOptionsList(options);
          }
        });
      

      

        clearInterval(pollInterval);
        const interval = setInterval(() => {
          console.log("==============DeliveryTime getQuoteTimeOptions interval call===============");
          props.updateCounterStatus(0); 
          if(props.completed.get(3) && !props.completed.get(5))
            getQuoteTimeOptions();
        }, 90000);
        setPollInterval(interval);       
        //props.updateCounterStatus(1); 
        return () => clearInterval(pollInterval);
      }
    }
    catch(e){console.log("DeliveryTime useEffect exception",e)};
  }, [props.completed.get(3), refresh, props.info[2].quoteDescriptions_data?.weight, props.info[2].quoteDescriptions_data?.pieces]);
*/

const getQuoteTimeOptions = () => {
  setIsLoading(true);
  props.updateCounterStatus(0); 
  httpRequest.getQuoteTimeOptions(props.info[0].quoteId).then((res) => {
    console.log("getQuoteTimeOptions", JSON.stringify(res.data));
    if(res.data?.length === 1 && res.data[0].optionText === undefined) return;
    setQuoteTimeOptionsList(res.data); 
    setSelect(-1);  
    props.onAlert({"target": "timeOption", "value": true});
    props.onChange({"target": "timeOption", "value": null});
    props.updateCounterStatus(1);
  }).finally(() => {
    setIsLoading(false);
  });
}

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRefreshClick = () => {
    getQuoteTimeOptions();
    resetPollInterval();  
  };


  const handleClick = (index) => (event) => {
    props.onAlert({"target": "timeOption", "value": false});
    props.onChange({"target": "timeOption", "value": quoteTimeOptionsList.find(e => e.timeOptionID === index)});
    setSelect(index);     
  };

  const clear = () => {
    setPhoneValue('');
    setNameValue('');
  };

  useEffect(() => {
    console.log("deliveryTimeInfo", JSON.stringify(deliveryTimeInfo));
    // if (!deliveryTimeInfo.readyAtDateChecked && !deliveryTimeInfo.deliveryTimeChecked) return;
    // clearInterval(pollInterval);
    // httpRequest.postQuoteCustomTime(
    //   props.info[0].quoteId,
    //   deliveryTimeInfo.readyAtDateChecked ? deliveryTimeInfo.readyAtDate : new Date(),
    //   deliveryTimeInfo.deliveryTimeChecked ? deliveryTimeInfo.deliveryTime : null
    // ).then((res) => {
    //   console.log("postQuoteCustomTime", JSON.stringify(res));
    //   props.updateCounterStatus(0);    
    //   httpRequest.getQuoteTimeOptions(props.info[0].quoteId).then((res) => {
    //     console.log("getQuoteTimeOptions", JSON.stringify(res.data));
    //     setQuoteTimeOptionsList(res.data);      
    //     setSelect(-1);  
    //     props.updateCounterStatus(1);
    //   });  
    //   resetPollInterval();
    // });
  }, [ deliveryTimeInfo.readyAtDate,deliveryTimeInfo.deliveryTime]);


  const handleChange = (event) => {
    console.log(`${event.target.name}--${event.target.value}`); 
    setDeliveryTimeInfo(oldValues => ({...oldValues, [event.target.name]: event.target.value}));  
    setDeliveryTimeInfo(oldValues => ({...oldValues, [event.target.name]: event.target.value}));  
    /*
    if(event.target.name === 'readyAtDate'|| event.target.name === 'deliveryTime' ) {
      clearInterval(pollInterval);
      httpRequest.postQuoteCustomTime(
        props.info[0].quoteId,
        deliveryTimeInfo.readyAtDateChecked &&event.target.name==='readyAtDate'? event.target.value : new Date(),
        deliveryTimeInfo.deliveryTimeChecked && event.target.name==='deliveryTime'? event.target.value : null
      ).then((res) => {
        console.log("postQuoteCustomTime", JSON.stringify(res));
        props.updateCounterStatus(0);    
        httpRequest.getQuoteTimeOptions(props.info[0].quoteId).then((res) => {
          console.log("getQuoteTimeOptions", JSON.stringify(res.data));
          setQuoteTimeOptionsList(res.data);      
          setSelect(-1);  
          props.onAlert({"target": "timeOption", "value": true});
          props.onChange({"target": "timeOption", "value": null});
          props.updateCounterStatus(1);
        });  
        resetPollInterval();
      });
    } 
    */
    if(event.target.name === 'readyAtDate') {
      clearInterval(pollInterval);
      httpRequest.postQuoteCustomTime(
        props.info[0].quoteId,
        event.target.value,
        deliveryTimeInfo.deliveryTimeChecked? deliveryTimeInfo.deliveryTime: null
      ).then((res) => {
        console.log("postQuoteCustomTime", JSON.stringify(res));
        getQuoteTimeOptions();
        resetPollInterval();   
      });
    }    
    if(event.target.name === 'deliveryTime' ) {
      clearInterval(pollInterval);
      httpRequest.postQuoteCustomTime(
        props.info[0].quoteId,
        deliveryTimeInfo.readyAtDateChecked? deliveryTimeInfo.readyAtDate: new Date(),
        event.target.value
      ).then((res) => {
        console.log("postQuoteCustomTime", JSON.stringify(res));
        getQuoteTimeOptions();
        resetPollInterval();   
      });
    } 
    if(event.target.name === 'readyAtDateChecked' && !event.target.value && deliveryTimeInfo.readyAtDate) {
      clearInterval(pollInterval);
      setDeliveryTimeInfo(oldValues => ({...oldValues, readyAtDate: null}));   
      httpRequest.postQuoteCustomTime(
        props.info[0].quoteId,
        new Date(),
        deliveryTimeInfo.deliveryTimeChecked? deliveryTimeInfo.deliveryTime: null
      ).then((res) => {
        console.log("postQuoteCustomTime", JSON.stringify(res));
        getQuoteTimeOptions();
        resetPollInterval();   
      });
    }  
    if(event.target.name === 'deliveryTimeChecked' && !event.target.value && deliveryTimeInfo.deliveryTime) {
      clearInterval(pollInterval);
      setDeliveryTimeInfo(oldValues => ({...oldValues, deliveryTime: null}));   
      httpRequest.postQuoteCustomTime(
        props.info[0].quoteId,
        deliveryTimeInfo.readyAtDateChecked? deliveryTimeInfo.readyAtDate: new Date(),
        null
      ).then((res) => {
        console.log("postQuoteCustomTime", JSON.stringify(res));
        getQuoteTimeOptions();
        resetPollInterval();   
      });
    }        
  };

  const handleNextPanel = () => {    
    props.onNextPanel();
  };

  const LoadingSkeleton = () => (
    <Grid container md direction="column" style={{ marginTop: 5, marginBottom: 25 }}>
      <Skeleton variant="rectangular" height={200} style={{ marginLeft: 5, marginRight: 5, borderRadius: 10 }} />
    </Grid>
  );

  return (
    <Grid
      container
      direction="row"
      style={{ paddingLeft: "1%", paddingRight: "1%" }}
    >     
      <Grid item sm={12} md={6}>        
        <RadioWithDateSelect
          header="Pick Up Now?"
          optionOne="Yes"
          optionTwo="No"
          onChange={handleChange}
          name="readyAtDate"
          value={deliveryTimeInfo.readyAtDateChecked!==undefined?deliveryTimeInfo.readyAtDateChecked:false}
        />        
      </Grid>
      <Grid container item sm={12} md={6}>
        <Grid item xs={10}>
        <RadioWithDateSelect 
          header='Specify Delivery By Time?' 
          optionOne='No' 
          optionTwo = 'Yes' 
          onChange={handleChange} 
          name="deliveryTime"
          value={deliveryTimeInfo.deliveryTimeChecked!==undefined?deliveryTimeInfo.deliveryTimeChecked:false}
          />
        </Grid>
        <Grid item xs={2}>
          <Box display="flex" justifyContent="flex-end" sx={{ width: '100%' }}>
            {props.info[0].Internal && (
              <Button variant='contained' color='primary' style={{ width:160 }} disabled={pollInterval === null} onClick={handleRefreshClick}> 
                Refresh
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>      
      <Grid item sm={12} style={{ marginTop: 20 }}>
        <Typography variant="h6" color="primary">
          Quick Select
        </Typography>
      </Grid>
      {isLoading ? (
        <>
          {[1,2,3].map((n) => (
            <Grid container md direction="column" key={n} justifyContent="center" style={{ marginTop: 5, marginBottom: 25 }}>
              <Skeleton variant="rounded" height={200} style={{ marginLeft: 5, marginRight: 5 }}>
                <Grid container sm={12} direction="column" style={{ paddingBottom: 5, minWidth: 160 }}>
                  <Grid item sm={12}>
                    <Grid container sm={12} alignItems="center">
                      <Grid item sm={3} xs={3}>
                        <Skeleton variant="circular" width={40} height={40} />
                      </Grid>
                      <Grid item sm={9} xs={9}>
                        <Skeleton variant="text" width="90%" />
                        <Skeleton variant="text" width="70%" />
                        <Skeleton variant="text" width="60%" />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Skeleton>
            </Grid>
          ))}
        </>
      ) : (
        quoteTimeOptionsList.map((item, index) => {
          const today = new Date();
          const startTime = new Date(item.dtDeliveryStart);
          const endTime = new Date(item.dtDeliveryEnd);
          return (
            <Grid container md direction="column" key={item.timeOptionID} justifyContent="center" style={{ marginTop: 5, marginBottom: 25 }}>
              <Button 
                variant="outlined" 
                size="large" 
                disabled={item.optionDisabled} 
                onClick={handleClick(item.timeOptionID)} 
                style={{maxWidth:'500px', height: 200, marginLeft: 5, marginRight: 5, paddingTop: 15, paddingBottom: 5, borderRadius: 10, borderColor: (select === item.timeOptionID) ? "#337AB7" : "", borderWidth: (select === item.timeOptionID) ? 4 : "", backgroundColor: (select === item.timeOptionID) ? "#337AB720" : "" }}>
                
                <Grid container sm={12} direction="column" style={{ paddingBottom: 5, minWidth: 160 }}>
                  <Grid item sm={12}>
                    <Typography variant="body1" gutterBottom align="left" style={{ marginTop: 5, fontWeight: 900 }}>
                      <Grid container sm={12} alignItems="center">
                        <Grid item sm={3} xs={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <img class='quickIcon' src={item.optionIconURL} alt={props.optionText} style={{ marginRight: 8 }} />
                        </Grid>
                        <Grid item sm={9} xs={9} style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                          <Typography variant="h6" gutterBottom align="left" style={{ textTransform: "none", marginLeft: 15 }}>
                            {item.optionText}
                          </Typography>
                          <Typography variant="body1" gutterBottom align="left" style={{ textTransform: "none", marginLeft: 15, fontWeight: 700 }}>
                            {item.optionSubText}
                          </Typography>
                          <Typography variant="body1" gutterBottom align="left" style={{ textTransform: "none", marginLeft: 15, fontWeight: 600 }}>
                            {item.optionLine1Text}
                          </Typography>
                          <Typography variant="body1" gutterBottom align="left" style={{ textTransform: "none", marginLeft: 15, fontWeight: 600 }}>
                            {item.optionLine2Text}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                  </Grid>
                  
                  <Divider />
                  
                  <Grid container xs={12} direction="row">
                    <Grid item container xs={6} justifyContent="flex-start">
                      {item.optionSpecialText && 
                        <Typography variant="body1" gutterTop align="center" color="error" style={{ fontWeight: 600 }}>
                          <img src="https://directitwebicons.blob.core.windows.net/img/icon/warning.png" style={{ height: '1em', marginTop: 10 }} />
                          {item.optionSpecialText}
                        </Typography>
                      }
                    </Grid>
                    <Grid item container xs={6} justifyContent="flex-end">
                      <Typography variant="body1" gutterBottom align="left" style={{ fontWeight: 800 }}>
                        {item.showInfo === 1 && (
                          <CustomTooltip content={item.infoText}>
                            <IconButton>
                              <InfoIcon color="primary" />
                            </IconButton>
                          </CustomTooltip>
                        )}
                        {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'CAD' }).format(item.RATE)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Button>
            </Grid>
          )
        })
      )}
      {props.info[1].pickUpLocation && 
        (
          <Dialog open={open} onClose={handleClose}>
            <DialogContent>
              <Typography variant="h6" gutterBottom>
                {props.info[1].pickUpLocation.companyName}
              </Typography>
              <Typography variant="body1" gutterBottom>
                {props.info[1].pickUpLocation.suite === '' ? '' : props.info[1].pickUpLocation.suite + ","} {props.info[1].pickUpLocation.address}, {props.info[1].pickUpLocation.quad}
              </Typography>
              <Typography variant="body1" gutterBottom>
                {props.info[1].pickUpLocation.city}, {props.info[1].pickUpLocation.prov}, {props.info[1].pickUpLocation.country}, {props.info[1].pickUpLocation.postal}
                <Tooltip title={props.info[1].pickUpLocation.addressID} placement="top" arrow>
                  <LocationOnIcon style={{fontSize: "14px"}}/>
                </Tooltip> 
              </Typography>
              <Typography variant="body1" display="inline" style={{fontWeight: 900}}>Last delivery completed: </Typography>
              <Typography variant="body1" display="inline"> {new Date(props.info[1].pickUpLocation.LastDate).toLocaleDateString()} </Typography>
              <Tooltip title={`${props.info[1].pickUpLocation.lat}, ${props.info[1].pickUpLocation.long}`} placement="top" arrow>
                <GpsFixedIcon style={{fontSize: "14px", display: "inline"}}/>
              </Tooltip>
              <Grid container sm={12} direction="row" style={{marginTop: 15}}>
                <Grid item sm={5}>
                    <TextInput name="contactName" type="name" label="Name" color="secondary" labelSize={5} variant="outlined" size="small"/>
                </Grid>
                <Grid item sm={5}>
                    <TextInput name="contactPhone" type="tel" label="Phone" color="secondary" labelSize={5} variant="outlined" size="small"/>
                </Grid>
                <Grid item sm={2}>
                  <Tooltip title={"save"} placement="top" arrow>
                    <Button color="secondary" style={{
                          root: {
                            border: '1px solid #ced4da',
                            backgroundColor: palette.primary.light,
                            color: palette.primary.main,
                            minWidth: '40px',
                            width: '40px',
                            height:'100%',
                            '&:hover': {
                                backgroundColor: palette.primary.main,
                                color: palette.primary.light,
                            },
                        },
                        textSecondary: {                    
                            backgroundColor: palette.error.light,
                            color: palette.error.dark,                            
                            '&:hover': {
                                backgroundColor: palette.error.main,
                                color: palette.error.light,
                            },
                        },
                        outlinedPrimary: {
                            backgroundColor: 'transparent',
                            color: (props) => props.selected ? 'white' : palette.primary.muted,                            
                            '&:hover': {
                                backgroundColor: (props) => props.selected ? 'white' : 'transparent',
                                color: palette.primary.main,
                            },
                        }
                      }}>
                      <DoneIcon color="secondary" variant="outlined"/>
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
        )
      }

      <Grid item sm={12}>
        <AccordionNextButton 
          onClick={handleNextPanel}
          disabled={select === -1}  // Disabled when no time option selected
          errorMessage={select === -1 ? 'Please select a delivery time option.' : ''}
        />
      </Grid>
    </Grid>
  );
};

export default DeliveryTime;