import React, { useState, useEffect, useRef,useReducer } from 'react';
import Grid from '@material-ui/core/Grid';
import './AddressForm.css'
import CheckIcon from '@material-ui/icons/Check';
import Button from '@material-ui/core/Button';
import ClearIcon from '@material-ui/icons/Clear';
//import { addressFormFields } from './addressFormFields';
import TextInput from '../../inputFields/TextInput';
import httpRequest from '../../../../HttpService';
import { palette } from '../../../../palette';
import { useUserContext} from "../../../../lib/user_context";

function updateAddress(state, action) {
    console.log(`updateAddress state is ${action.type} action is ${action.value}`)
    state[action.type] = action.value
    const obj = {...state}
    obj[action.type] = action.value
    return obj;
}

const AddressForm = props => {    
    const { sessionAccountAddress} = useUserContext();
     //Init google maps
     const [googleAPILoaded, setGoogleAPILoaded] = useState(false);
     const[addressFormFields, setAddressFormFields] = useState(null);
     const [orgAddressData, setOrgAddressData] = useState(null)

     const initAddress = {
        companyName: '',
        address: '',
        suite: '',
        quad: '',
        city: '',
        prov: 'AB',
        country: '',
        postal: ''
    }
    const [address, dispatch] = useReducer(updateAddress, initAddress);
   

     var addressData = {};     
     useEffect(() => {
        let addressFields = [
            [{
                name: 'companyName',
                type: 'name',
                label: 'Name',
                required: true,
            }],
            [{
                name: 'address',
                type: 'address',
                label: 'Address',
                required: true,
            }],
            [{
                name: 'suite',
                type: 'suite',
                label: 'Suite',
                required: false,
            },
            {
                name: 'quad',
                type: 'quadrant',
                label: 'Quadrant',
                required: false,
            }],
            [{
                name: 'city',
                type: 'city',
                label: 'City',
                required: true,
            },
            {
                name: 'prov',
                type: 'province',
                label: 'Province',
                required: true,
            }],
            [{
                name: 'country',
                type: 'country',
                label: 'Country',
                required: true,
            },
            {
                name: 'postal',
                type: 'postalcode',
                label: 'Postal Code',
                required: true,
            }],
            [{
                name: 'contactName',
                type: 'name',
                label: 'Contact Name',
                required: false,
            }],
            [{
                name: 'contactPhone',
                type: 'tel',
                label: 'Contact Phone',
                required: false,
            }],
        ];
        if (props.data) {
             console.log("AddressForm " + JSON.stringify(props.data));
             addressData = props.data; //get address data
             setOrgAddressData(props.data);
            
             //set default values
             addressFields.map((row) => {
                 row.map((item) => {
                    item.defaultValue = addressData[item.name];
                    dispatch({ type: item.name, value: addressData[item.name] });
                 })
             });    
            
            resetFieldWithNewAddress(addressData);                      
         }
         else{
            addressFields.map((row) => {
                row.map((item) => {
                    item.defaultValue = "";
                })
            });      
         }
         setAddressFormFields(addressFields);

     },[props.data]);




     useEffect(() => {
         const loadGoogleMapScript = (callback) => {
             if (typeof window.google === 'object' && typeof window.google.maps === 'object') {
                 console.log("window google or window google maps")
                 callback();
             } else {
               const googleMapScript = document.createElement("script");
               googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`;
               window.document.body.appendChild(googleMapScript);
               console.log("creating event listening google map")
               googleMapScript.addEventListener("load", callback);
             }
         }
 
         loadGoogleMapScript(() => {
             console.log("google api script loaded")
             setGoogleAPILoaded(true);
         });
     }, []);


     //autocomplete search
     const placeInputRef = useRef(null);
     const placeNameInputRef = useRef(null);
     const companyNameRef = useRef(null);
     const [newAddressValues, setNewAddressValues] = useState(null);
     const [addressSearchResult, setAddressSearchResult] = useState(null);
     const [googleResultAddress, setGoogleResultAddress] = useState(null);

     useEffect(() => {
         if(googleAPILoaded) {
             initPlaceAPI();
         }
     }, [googleAPILoaded]);


      
     // initialize the google place autocomplete
     const initPlaceAPI = () => {         
        console.log("init place api wiht listener");
       // var defaultAddress = JSON.parse(localStorage.getItem('userAddress'));   
       var defaultAddress = JSON.parse(sessionAccountAddress);
        var geolocation = {
            lat:defaultAddress.lat,
            lng:defaultAddress.long
        };     

        var circle = new window.google.maps.Circle({center: geolocation, radius: 5000});
        let options = {
             bounds:circle.getBounds()
        };

        let autocomplete = new window.google.maps.places.Autocomplete(placeInputRef.current, options);
        new window.google.maps.event.addListener(autocomplete, "place_changed", function () {
             let place = autocomplete.getPlace();
             let placeFormatted = convertPlaceToFriendlyObject(place);
             console.log("initPlaceAPI-" + JSON.stringify(placeFormatted));
             populateMatchedAddress(placeFormatted);
             
        });

        let autocompleteName = new window.google.maps.places.Autocomplete(placeNameInputRef.current, options);
        new window.google.maps.event.addListener(autocompleteName, "place_changed", function () {
             let place = autocompleteName.getPlace();
             let placeFormatted = convertPlaceToFriendlyObject(place);
             console.log("initPlaceAPI-" + JSON.stringify(placeFormatted));
             populateMatchedAddress(placeFormatted);
             
        });
    };
 
     function convertPlaceToFriendlyObject(place) {
         let result = {};
         if(place) {
             let components = place.address_components;
 
             for( let i = 0; i < components.length; i++ ) {
                 if (i === 0) {
                     result.searchBy = components[i].types[0];
                 }
                 result[components[i].types[0]] = components[i].long_name;
             }
         }
         result.formattedAddress = place.formatted_address;
         result.lat = place.geometry.location.lat();
         result.lng= place.geometry.location.lng();
         result.name = place.name;
         result.place_id = place.place_id;

         setAddressSearchResult({
            "json" :JSON.stringify(place),
            "place_id": result.place_id
         });

        setGoogleResultAddress(result);
 
        return result;
 
     }
 
     function populateMatchedAddress (myAddress) {
         let tempAddressValues = {};
         if(myAddress.route !== undefined){
             if(myAddress.route.endsWith("Southwest")) {
                 tempAddressValues.quad = "SW";
                 tempAddressValues.address = myAddress.street_number + ' ' + myAddress.route.substring(0, myAddress.route.indexOf("Southwest"));
             }
             else if(myAddress.route.endsWith("Southeast")) {
                 tempAddressValues.quad = "SE";
                 tempAddressValues.address = myAddress.street_number + ' ' + myAddress.route.substring(0, myAddress.route.indexOf("Southeast"));
             }
             else if(myAddress.route.endsWith("Northwest")) {
                 tempAddressValues.quad = "NW";
                 tempAddressValues.address = myAddress.street_number + ' ' + myAddress.route.substring(0, myAddress.route.indexOf("Northwest"));
             }
             else if(myAddress.route.endsWith("Northeast")) {
                 tempAddressValues.quad = "NE";
                 tempAddressValues.address = myAddress.street_number + ' ' + myAddress.route.substring(0, myAddress.route.indexOf("Northeast"));
             }
             else {
                 tempAddressValues.quad = "";
                 tempAddressValues.address = myAddress.street_number + ' ' + myAddress.route;
             }
         }
         else {
             let arr = myAddress.formattedAddress.split(',');
             if(arr[0].includes('Southwest')) {
                 tempAddressValues.quad = "SW";
                 tempAddressValues.address = arr[0].substring(0,arr[0].indexOf('Southwest'));
             }
             else if(arr[0].includes('Southeast')) {
                 tempAddressValues.quad = "SE";
                 tempAddressValues.address = arr[0].substring(0,arr[0].indexOf('Southeast'));
             }
             else if(arr[0].includes('Northwest')) {
                 tempAddressValues.quad = "NW";
                 tempAddressValues.address = arr[0].substring(0,arr[0].indexOf('Northwest'));
             }
             else if(arr[0].includes('Northeast')) {
                 tempAddressValues.quad = "NE";
                 tempAddressValues.address = arr[0].substring(0,arr[0].indexOf('Northeast'));
             }
             else {
                 tempAddressValues.quad = '';
                 tempAddressValues.address = arr[0];
             }
         }
 
         tempAddressValues.city = myAddress.locality;
         tempAddressValues.country = myAddress.country;
         tempAddressValues.postal = myAddress.postal_code;
         tempAddressValues.lat = myAddress.lat;
         tempAddressValues.long = myAddress.lng;
         tempAddressValues.prov =  myAddress.administrative_area_level_1;
         tempAddressValues.companyName =  myAddress.name;
         
        resetFieldWithNewAddress(tempAddressValues);  
     }


    function resetFieldWithNewAddress(addressData){
        try{          
            dispatch({ type: 'address', value: addressData.address });
            dispatch({ type: 'quad', value: addressData.quad });
            dispatch({ type: 'city', value: addressData.city });
            dispatch({ type: 'postal', value: addressData.postal });
            dispatch({ type: 'country', value: addressData.country });
            dispatch({ type: 'prov', value: addressData.prov });
            if(addressData.companyName)
              dispatch({ type: 'companyName', value: addressData.companyName });
             
        }
        catch(e){
            console.log("resetFieldWithNewAddress exception:" + e);
        }
    }


    function addDashes(f){
        const f_val = f.replace(/\D[^\.]/g, "");
        if(f_val.length <= 3) {
            return f_val;
        }
        if (f_val.length <= 6) {
            return f_val.slice(0,3)+"-"+f_val.slice(3,6);
        }
        return f_val.slice(0,3)+"-"+f_val.slice(3,6)+"-"+f_val.slice(6);
    }
    const processPhoneNumber = (event) => {
        const formattedPhone = addDashes(event.target.value);
        event.target.value=formattedPhone;
      
    }

    const submitAddressForm = (event) => {
        console.log("AddressForm  Form Submitted   accountNumber is--------" + props.accountNumber);
        event.preventDefault();

        //Update Address Data
        if(orgAddressData !== null) 
           addressData = orgAddressData;
        else
           addressData = {};   
        addressData.companyName = event.target.companyName.value;
        addressData.address = event.target.address.value;
        addressData.suite = event.target.suite.value;
        addressData.quad = event.target.quad.value;
        addressData.city = event.target.city.value;
        addressData.prov = event.target.prov.value;
        addressData.country = event.target.country.value;
        addressData.postal = event.target.postal.value;
        addressData.contactName = event.target.contactName.value;
        addressData.contactPhone = event.target.contactPhone.value;

        if(googleResultAddress){
            addressData.lat = googleResultAddress.lat;
            addressData.long = googleResultAddress.lng;
        }

        console.log('---Address data submitted', JSON.stringify(addressData));
        //post Address
        httpRequest.postAddress(props.accountNumber, addressData).then((response) => {
            //console.log(response);  
            addressData.addressID = response.data[0].addressID;
            console.log(props.accountNumber + '---addressID:', addressData.addressID);
 
            httpRequest.addContact(props.accountNumber, addressData).then((res1) => {
                console.log('addContact', props.accountNumber);
                console.log('contact added----', res1.data[0].contactID);
                addressData.contactID = res1.data[0].contactID;
                httpRequest.updateAddressContact(props.accountNumber, addressData.addressID, res1.data[0].contactID).then(res2 => {
                    console.log('update address contact', props.accountNumber);
                    console.log('update address contact', res2);
                                      
                    httpRequest.getAddressByID(props.accountNumber, addressData.addressID).then(data => {
                            data.data[0].contactID = addressData.contactID;
                            if (props.variant === 'add') 
                                props.newAddress(data.data[0]);
                            else 
                                props.updatedAddress(data.data[0]);    
                    });                     
                });
            });

           

            if(addressSearchResult!== null){
                httpRequest.postAddressEXT(addressData.addressID, addressSearchResult).then(res => {
                    console.log('postAddressEXT', res.data); 
                    setAddressSearchResult(null);                 
                });
            }
            if(props.variant === 'add')
                props.afterSaveAddress(); //close form               
            else
                props.cancelButtonOnClick(); //close form    
        });
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={0}
            style={{ 
                borderColor: palette.primary.light, 
                background: palette.greys.white 
            }}
            className={props.variant === 'add' ? "addNewAddressForm" : "editAddressForm"}
            >
            {addressFormFields && <form onSubmit={submitAddressForm}>
                <h3 style={{
                        color: palette.primary.main,
                        paddingBottom: '5%',
                    }}
                >
                    {props.title}
                </h3>
                {addressFormFields.map((row) => {
                    return (
                        <Grid container spacing={1} style={{margin:'1px 0'}}>
                            {row.map((item) => {
                                return(
                                    <Grid item sm={12} md>
                                        {item.name === 'address' || item.name === 'companyName' ?<TextInput 
                                            name={item.name}
                                            type={item.type}
                                            id={item.name} 
                                            label={item.label}
                                            labelSize={5}
                                            variant="outlined" 
                                            size="small"
                                            //defaultValue={item.defaultValue ? item.defaultValue : ''}  
                                            value = {address[item.name]}                           
                                            required={item.required}
                                            inputRef={item.name === 'address'? placeInputRef: 
                                                item.name === 'companyName' ? companyNameRef : 
                                                placeNameInputRef}
                                            onChange={(event) => {
                                                dispatch({ type: item.name, value: event.target.value });
                                            }}
                                        /> :
                                        <TextInput 
                                            name={item.name}
                                            type={item.type}
                                            id={item.name} 
                                            label={item.label}
                                            labelSize={item.name === 'postal' ? 7 : 5}
                                            variant="outlined" 
                                            size="small"
                                            //defaultValue={item.defaultValue ? item.defaultValue : ''}
                                            value = {address[item.name]}     
                                            required={item.required}
                                            onChange={(event) => {
                                                dispatch({ type: item.name, value: event.target.value });
                                            }}
                                        />}
                                    </Grid>
                                )
                            })}
                        </Grid>
                    )
                })}
                <Grid container style={{ padding: '5% 1%' }} justifyContent="center" sm={12} spacing={1}>
                    <Grid item>
                        <Button
                            variant="contained"
                            style={{ color: palette.greys.white, backgroundColor: palette.greys.medium}}
                            startIcon={<ClearIcon style={{verticalAlign: 'middle', transform: 'scale(1.5)', color: palette.greys.white }}/>}
                            disableRipple 
                            onClick={props.cancelButtonOnClick}
                        >
                            {props.cancelButtonLabel}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<CheckIcon style={{verticalAlign: 'middle', transform: 'scale(1.5)', color: palette.greys.white }}/>}
                            disableRipple 
                            //onClick={submitAddressForm}
                            type="submit"
                        >
                            {props.saveButtonLabel}
                        </Button>
                    </Grid>
                </Grid>
            </form>}
        </Grid>
    );

};
export default AddressForm;
