import React, { useState, useEffect } from "react";
import Grid from '@material-ui/core/Grid';
import { Checkbox, Box } from '@mui/material';
import Typography from '@material-ui/core/Typography';
import styles from '../inputFields/styles.js';
import './styles/TagContainer.css';
import TagDropdown from "./TagDropdown";
import TagTextFielsEditable from "./TagTextFielsEditable";
import TagNumberFiels from "./TagNumberFiels";
import TagDecimalField from "./TagDecimalFields.js";
import TagDatetimeField from "./TagDatetimeFields.js";
import TagCheckBox from "./TagCheckBox.js";
import TagDatePicker from "./TagDatePicker.js";
import TagTimePicker from "./TagTimePicker.js";
import TagMemo from "./TagMemoFields.js";
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { createTheme, withStyles, ThemeProvider } from '@material-ui/core/styles';

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    margin: 0,
    border: 0,
    direction: "horizontal",
    flexWrap: 'wrap',
  }
}))(ToggleButtonGroup);

const StyledToggleButton = withStyles({
  root: {
    padding: 0,
    textTransform: "none",
    display: "block",
    width: "280px",
    "&:hover": {},
  },
  selected: {}
})(ToggleButton);

const theme = createTheme({
  palette: {
    action: {
      active: "#221d26"
    },
    text: {
      primary: "#221d26"
    }
  }
});

const TagContainer = (props) => {
  console.log("Rendering TagContainer for tag:", props.info.quoteTagID);

  const [checked, setChecked] = useState(props.info.checked);

  useEffect(() => {
    console.log("TagContainer", "info-" + JSON.stringify(props.info));
    setChecked(props.info.checked);
  }, [props.info]);

  useEffect(() => {
    console.log("TagContainer", "value-" + props.value);
  }, [props.value]);

  const handleCheck = () => {
    // If the tag is alwaysOn, prevent unchecking
    if (props.info.alwaysOn) {
      return;
    }

    const newChecked = !checked;
    setChecked(newChecked);
    props.onCheckedChange(props.info.quoteTagID, newChecked);

    if (newChecked) {
      props.restrictions.forEach((item) => {
        if (item.type !== "dropdown" && item.type !== "datetime" && item.type !== "date" && item.type !== "time" && item.type !== "checkbox") {
          if (item.required) {
            props.onAlert.addAlert(item.prompt);
          }
        }
      });
    } else {
      props.restrictions.forEach((item) => {
        if (item.type !== "dropdown" && item.type !== "datetime" && item.type !== "date" && item.type !== "time" && item.type !== "checkbox") {
          if (item.required) {
            props.onAlert.removeAlert(item.prompt);
          }
        }
      });
    }
  };

  const renderTagField = (item) => {
    return (
      item.newline ? <Box width="100%" /> : <Grid item>
        {item.type === "integer" && <TagNumberFiels
          key={props.key}
          label={item.prompt}
          param={item.param}
          variant="outlined"
          minNum={item.min ? item.min : 0}
          maxNum={item.max ? item.max : 10000}
          prompt={item.mo}
          required={item.required}
          onAlert={props.onAlert}
          onChange={props.onChange}
          info={props.element}
          value={item.value}
        />}
        {item.type === "varchar" &&
          <TagTextFielsEditable
            key={props.key}
            label={item.prompt}
            param={item.param}
            variant={props.variant}
            prompt={item.mo}
            maxLength={item.length}
            required={item.required}
            onAlert={props.onAlert}
            onChange={props.onChange}
            value={item.value}
          />}
        {item.type === "decimal" &&
          <TagDecimalField
            key={props.key}
            label={item.prompt}
            param={item.param}
            variant={props.variant}
            prompt={item.mo}
            minNum={item.min ? item.min : 0}
            maxNum={item.max ? item.max : 10000}
            required={item.required}
            onAlert={props.onAlert}
            onChange={props.onChange}
            value={item.value}
          />}
        {item.type === "datetime" &&
          <TagDatetimeField
            key={props.key}
            label={item.prompt}
            param={item.param}
            variant={props.variant}
            prompt={item.mo}
            required={item.required}
            onAlert={props.onAlert}
            onChange={props.onChange}
            value={item.value}
          />}
        {item.type === "date" &&
          <TagDatePicker
            key={props.key}
            label={item.prompt}
            param={item.param}
            variant={props.variant}
            prompt={item.mo}
            required={item.required}
            onAlert={props.onAlert}
            onChange={props.onChange}
            value={item.value}
          />}
        {item.type === "time" &&
          <TagTimePicker
            key={props.key}
            label={item.prompt}
            param={item.param}
            variant={props.variant}
            prompt={item.mo}
            required={item.required}
            onAlert={props.onAlert}
            onChange={props.onChange}
            value={item.value}
          />}
        {item.type === "dropdown" &&
          <TagDropdown
            key={props.key}
            label={item.prompt}
            param={item.param}
            variant={props.variant}
            prompt={item.mo}
            maxLength={item.length}
            required={item.required}
            onAlert={props.onAlert}
            onChange={props.onChange}
            choices={item.choices}
            value={item.value}
          />}
        {item.type === "checkbox" &&
          <TagCheckBox
            key={props.key}
            label={item.prompt}
            param={item.param}
            variant={props.variant}
            prompt={item.mo}
            required={item.required}
            onAlert={props.onAlert}
            onChange={props.onChange}
            value={item.value}
          />}
        {item.type === "memo" &&
          <TagMemo
            key={props.key}
            label={item.prompt}
            param={item.param}
            variant={props.variant}
            prompt={item.mo}
            maxLength={item.length}
            required={item.required}
            onAlert={props.onAlert}
            onChange={props.onChange}
            value={item.value}
          />}
      </Grid>
    );
  };

  return (
    <Grid container className="grid-container" spacing={1}>
      <Grid item xs={12} sm={12} md={5} lg={2}>
        <Grid container direction="row">

          <Grid item xs={12}>
            <Grid container direction="row">
              <Grid className="checkbox-container">
                <ThemeProvider theme={theme}>
                  <StyledToggleButtonGroup
                    size="small"
                    value={checked ? 'selected' : 'not-selected'}
                    exclusive
                    onChange={handleCheck}
                    disabled={props.info.alwaysOn}
                  >
                    <StyledToggleButton
                      value="selected"
                      disableRipple
                      className={`tag-button ${props.info.alwaysOn ? 'always-on' : ''}`}
                      style={{
                        cursor: props.info.alwaysOn ? 'not-allowed' : 'pointer',
                      }}
                    >
                      <Box
                        className={`label-wrapper-no-color ${checked ? 'tag-checked' : ''}`}
                        style={{
                          ...styles.labelWrapperNoColor,
                          borderColor: props.info.tagColor || '#9e9e9e',
                          backgroundColor: checked ? (props.info.tagColor || 'white') : '#f0f0f0',
                          opacity: checked ? 1 : 0.7
                        }}
                      >
                        {props.info.icon_url && <img
                          src={props.info.icon_url}
                          alt={props.label}
                          className="tag-icon"
                        />}
                        <Typography variant='subtitle1'
                          className="label-no-color"
                          style={{
                            ...styles.labelNoColor,
                            color: props.info.tagTextColor || '#000000'
                          }}
                        >
                          {props.info.label || props.label}
                          {props.info.alwaysOn ? " (Required)" : ""}
                        </Typography>
                      </Box>
                    </StyledToggleButton>
                  </StyledToggleButtonGroup>
                </ThemeProvider>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={7} lg={10} className="reference">
        <Grid container spacing={3}>
          {checked && props.restrictions.map((item) => renderTagField(item))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TagContainer;
