import React, { useState } from "react";
import { Box } from "@mui/material";
import Typography from "@material-ui/core/Typography";
import styles from "../inputFields/styles.js";
import "./styles/TagContainer.css";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { createTheme, withStyles, ThemeProvider } from "@material-ui/core/styles";

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    margin: 0,
    border: 0,
    display: "flex", // Ensure it behaves like a flex container
    flexWrap: "wrap", // Allow wrapping of child elements
    justifyContent: "flex-start", // Align items to the left
  },
}))(ToggleButtonGroup);

const StyledToggleButton = withStyles({
  root: {
    padding: 0,
    textTransform: "none",
    display: "block",
    width: "280px",
    "&:hover": {},
  },
  selected: {},
})(ToggleButton);

const theme = createTheme({
  palette: {
    action: {
      active: "#221d26",
    },
    text: {
      primary: "#221d26",
    },
  },
});

const InactiveTagContainer = (props) => {
  const [checked, setChecked] = useState(props.info.checked);

  const handleCheck = () => {
    const newChecked = !checked;
    setChecked(newChecked);
    props.onCheckedChange(props.info.quoteTagID, newChecked);

    if (newChecked) {
      props.restrictions.forEach((item) => {
        if (
          item.type !== "dropdown" &&
          item.type !== "datetime" &&
          item.type !== "date" &&
          item.type !== "time" &&
          item.type !== "checkbox"
        )
          if (item.required) props.onAlert.addAlert(item.prompt);
      });
    } else {
      props.restrictions.forEach((item) => {
        if (
          item.type !== "dropdown" &&
          item.type !== "datetime" &&
          item.type !== "date" &&
          item.type !== "time" &&
          item.type !== "checkbox"
        )
          if (item.required) props.onAlert.removeAlert(item.prompt);
      });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="tag-container">
        <StyledToggleButtonGroup
          size="small"
          value={checked ? "selected" : "not-selected"}
          exclusive
          onChange={handleCheck}
        >
          <StyledToggleButton value="selected" disableRipple>
            <Box
              className={`label-wrapper-no-color ${checked ? "tag-checked" : ""}`}
              style={{
                ...styles.labelWrapperNoColor,
                borderColor: props.info.tagColor || "#9e9e9e",
                backgroundColor: checked
                  ? props.info.tagColor || "white"
                  : "#f0f0f0",
                opacity: checked ? 1 : 0.7,
              }}
            >
              {props.info.icon_url && (
                <img
                  src={props.info.icon_url}
                  alt={props.label}
                  className="tag-icon"
                />
              )}
              <Typography
                variant="subtitle1"
                className="label-no-color"
                style={{
                  ...styles.labelNoColor,
                  color: props.info.tagTextColor || "#000000",
                }}
              >
                {props.label}
              </Typography>
            </Box>
          </StyledToggleButton>
        </StyledToggleButtonGroup>
      </div>
    </ThemeProvider>
  );
};

export default InactiveTagContainer;
